import interest1 from "../../../assets/images/interst/01.jpg";
import interest2 from "../../../assets/images/interst/02.jpg";
import interest3 from "../../../assets/images/interst/03.jpg";
import interest4 from "../../../assets/images/interst/04.jpg";
import interest5 from "../../../assets/images/interst/05.jpg";
import interest6 from "../../../assets/images/interst/06.jpg";
import interest7 from "../../../assets/images/interst/07.jpg";
import interest8 from "../../../assets/images/interst/08.jpg";
import interest9 from "../../../assets/images/interst/09.jpg";
import interest10 from "../../../assets/images/interst/10.jpg";

const interestData = [
  { _id: 1, img: interest1 },
  { _id: 2, img: interest2 },
  { _id: 3, img: interest3 },
  { _id: 4, img: interest4 },
  { _id: 5, img: interest5 },
  { _id: 6, img: interest6 },
  { _id: 7, img: interest7 },
  { _id: 8, img: interest8 },
  { _id: 9, img: interest9 },
  { _id: 10, img: interest10 },
  { _id: 11, img: interest1, },
  { _id: 12, img: interest3 },
];

export default interestData;
