// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*------------- Variable ------------*/

:root {
  --iq-primary: #e50914;
  --iq-primary-hover: #bf000a;
  --iq-light-primary: #d7d3f8;
  --iq-secondary: #6c757d;
  --iq-white: #ffffff;
  --iq-black: #000000;
  --iq-title-text: #23203c;
  --iq-body-text: #D1D0CF;
  --iq-body-bg: #141414;
  --iq-bg1: #191919;
  --iq-body-border: #393750;
  --iq-gold: #E6C75F;
  --iq-black: #000000;
  --iq-form-gradient-color: rgba(11, 1, 2, 0);
  --iq-to-gradient-color: rgba(255, 55, 65, 0.3);}
`, "",{"version":3,"sources":["webpack://./src/assets/css/variable.css"],"names":[],"mappings":"AAAA,sCAAsC;;AAEtC;EACE,qBAAqB;EACrB,2BAA2B;EAC3B,2BAA2B;EAC3B,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,wBAAwB;EACxB,uBAAuB;EACvB,qBAAqB;EACrB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,2CAA2C;EAC3C,8CAA8C,CAAC","sourcesContent":["/*------------- Variable ------------*/\r\n\r\n:root {\r\n  --iq-primary: #e50914;\r\n  --iq-primary-hover: #bf000a;\r\n  --iq-light-primary: #d7d3f8;\r\n  --iq-secondary: #6c757d;\r\n  --iq-white: #ffffff;\r\n  --iq-black: #000000;\r\n  --iq-title-text: #23203c;\r\n  --iq-body-text: #D1D0CF;\r\n  --iq-body-bg: #141414;\r\n  --iq-bg1: #191919;\r\n  --iq-body-border: #393750;\r\n  --iq-gold: #E6C75F;\r\n  --iq-black: #000000;\r\n  --iq-form-gradient-color: rgba(11, 1, 2, 0);\r\n  --iq-to-gradient-color: rgba(255, 55, 65, 0.3);}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
