import React from 'react'
import ExtraPages from '../router/extra-pages-route'

const BlankLayout =() => {  
    return(
        <>
            <div className="">
                <ExtraPages />
            </div>
       </>
    )
}

export default BlankLayout;